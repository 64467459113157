import React, { createRef, Component } from 'react';
import {
  arrayOf, bool, func, number, shape, string
} from 'prop-types';
import classNames from 'classnames';
import QuickPinchZoom, { make3dTransformValue } from 'react-quick-pinch-zoom';
import { Carousel } from '@thd-olt-component-react/carousel';
import { Col, Row, Image } from '@thd-olt-component-react/core-ui';
import { Overlay } from '@thd-olt-component-react/overlay';
import { VideoPlayer } from '@thd-olt-component-react/video-player';
import { PaintSwatches } from '@one-thd/sui-icons';
import {
  ARIcon, BackgroundCircleIcon, HotspotTagIcon, PlayIcon, ThreeSixtyIcon
} from '../Icons';
import Helper from '../../Helper';
import { mediaClick } from '../../../publisher';
import { InteractiveMediaOverlay } from './InteractiveMediaOverlay';
import './media-gallery-overlay-mobile.style.scss';

class MediaGalleryOverlayMobile extends Component {

  constructor(props) {
    super(props);
    this.videoRefs = [];
    this.mobileOverlayRef = createRef({});
    this.selectedQuickPinchZoomRef = createRef({});
    this.selectedZoomedImageRef = createRef({});

    const { selectedImageIndex, showAvataarAR } = props;
    this.state = {
      mainImage: null,
      mainImagesBuffer: [],
      secondOverlayOpened: false,
      trayThumbnails: [],
      trayMainImages: [],
      selectedImageIndex,
      isPortrait: true,
      reset: () => {},
    };
  }

  componentDidMount = () => {
    const { overlayOpened, mainImage, showAvataarAR } = this.props;

    if (showAvataarAR) {
      this.setState({ mainImage, secondOverlayOpened: true });
    } else {
      this.setTrayThumbnailsElements();
      this.setTrayMainImagesElements();
    }
    this.disableScroll(overlayOpened);
    this.checkResolution();
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', this.checkResolution, false);
    }
  };

  componentDidUpdate(_, prevState) {
    const { selectedImageIndex, reset } = this.state;
    if (prevState.selectedImageIndex !== selectedImageIndex) {
      this.setTrayThumbnailsElements();
      reset();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkResolution, false);
    document.body.style.overflow = 'auto';
  }

  checkResolution = () => {
    if (typeof window !== 'undefined') {
      const LANDSCAPE_MIN_WIDTH = 550;
      let isPortrait = true;
      const windowWidth = window.innerWidth;
      if (windowWidth >= LANDSCAPE_MIN_WIDTH) {
        isPortrait = false;
      }
      this.setState({ isPortrait });
    }
  };

  onChangeImage = (index) => {
    const { mainImages } = this.props;
    const { mainImagesBuffer } = this.state;

    if (this.selectedZoomedImageRef.current) {
      this.selectedQuickPinchZoomRef.current.scaleTo({ x: 0, y: 0, scale: 1 });
      this.selectedZoomedImageRef.current.style.setProperty('transform', 'scale3d(1, 1, 1) translate3d(0px, 0px, 0px)');
    }
    mainImagesBuffer[index] = mainImages[index];
    this.setState({
      selectedImageIndex: index,
      mainImagesBuffer
    });
    this.setTrayMainImagesElements();

    const payload = {
      section: 'expanded-view',
      component: 'thumbnails',
      target: mainImages[index].type,
      position: index + 1
    };

    mediaClick(payload);

  };

  openSecondaryOverlay = (mainImage) => {
    this.setState({
      secondOverlayOpened: true,
      mainImage
    });
    if (typeof window !== 'undefined') {
      window.history.pushState({ page: 'MEDIASECONDOVERLAY' }, null, '');
    }
  };

  setTrayThumbnailsElements = () => {
    const { product = {}, thumbnails, overrideData } = this.props;
    const { identifiers = {} } = product;
    const { brandName, productLabel } = identifiers;
    const { selectedImageIndex } = this.state;
    const trayThumbnails = thumbnails.map((thumb, thumbIndex) => {
      const thumbnailStyle = classNames(
        'overlay-mobile__thumbnail__image-block',
        { 'overlay-mobile__thumbnail__image-block--selected': (selectedImageIndex === thumbIndex) }
      );

      const { id, altText, rgb } = thumb;
      const altLabel = altText
      || `${overrideData?.brandNameLabel || brandName} ${overrideData?.galleryLabel || productLabel}`;
      const isImage360 = Helper.isImage360({ media: thumb });
      const isVideo = Helper.isVideo({ media: thumb });
      const isAR = Helper.isAR({ media: thumb });
      const isAvataarAR = Helper.isAvataarAR({ media: thumb });
      const isHotspot = Helper.isHotspot({ media: thumb });
      const isCustomColor = Helper.isCustomColor({ media: thumb });
      const isManualColor = Helper.isManualColor({ media: thumb });

      return (
        <div key={id} className="overlay-mobile__thumbnail__wrapper">
          <button
            type="button"
            className={thumbnailStyle}
            onClick={() => this.onChangeImage(thumbIndex)}
          >
            {isCustomColor || isManualColor ? (
              <>
                {isManualColor ? (
                  <div
                    className={classNames('sui-bg-inactive',
                      'sui-flex',
                      'sui-items-center',
                      'sui-justify-center',
                      'sui-w-full',
                      'sui-h-full'
                    )}
                  >
                    <div
                      className="sui-text-center sui-flex sui-items-center sui-justify-center"
                    >
                      <PaintSwatches color="medium" size="large" />
                    </div>
                  </div>
                ) : (
                  <div
                    className="overlay-mobile__thumbnail-custom-color"
                    style={{
                      backgroundColor: `rgb(${rgb.red},${rgb.green},${rgb.blue})`,
                    }}
                  >
                    <Image
                      alt={altLabel}
                      src={thumb.brandLogo}
                    />
                  </div>
                )}
              </>
            ) : (
              <>
                {(isImage360 || isVideo || isAR || isHotspot)
                  && (
                    <>
                      <BackgroundCircleIcon isThumbnail />
                      {isImage360 && (<ThreeSixtyIcon isThumbnail />)}
                      {isVideo && (<PlayIcon isThumbnail />)}
                      {isAR && isAvataarAR && (<ThreeSixtyIcon isThumbnail />)}
                      {isAR && !isAvataarAR && (<ARIcon isThumbnail />)}
                      {isHotspot && (<HotspotTagIcon isThumbnail />)}
                    </>
                  )}
                <Image
                  className="overlay-mobile__thumbnail-image"
                  src={thumb.mediaStill || thumb.videoStill || thumb.url}
                  alt={altLabel}
                  lazy
                />
              </>
            )}
          </button>
        </div>
      );
    });
    this.setState({
      trayThumbnails
    });
  };

  setTrayMainImagesElements = () => {
    const {
      product, mainImages, zoomedImages, overrideData, itemId, showAvataarDefault,
      showAvataarAR
    } = this.props;
    const { identifiers = {} } = product;
    const { brandName, productLabel } = identifiers;
    const altLabel = `${overrideData?.brandNameLabel || brandName} ${overrideData?.galleryLabel || productLabel}`;
    const { isPortrait, mainImagesBuffer, selectedImageIndex, secondOverlayOpened } = this.state;
    const mainImagesBufferTemp = mainImagesBuffer;
    if (mainImagesBuffer.length === 0) {
      while (mainImagesBufferTemp.length < mainImages.length) {
        mainImagesBufferTemp.push('');
      }
      mainImagesBufferTemp[selectedImageIndex] = mainImages[selectedImageIndex];
    }
    const trayMainImages = mainImagesBufferTemp.map((mainImage, imageIndex) => {
      const isImage = Helper.isImage({ media: mainImage });
      const isImage360 = Helper.isImage360({ media: mainImage });
      const isVideo = Helper.isVideo({ media: mainImage });
      const isAR = Helper.isAR({ media: mainImage });
      const isAvataarAR = Helper.isAvataarAR({ media: mainImage });
      const isHotspot = Helper.isHotspot({ media: mainImage });
      const isCustomColor = Helper.isCustomColor({ media: mainImage });
      const isManualColor = Helper.isManualColor({ media: mainImage });
      let videoEl = null;

      // if the asset is a video
      if (isVideo) {
        // grab the element
        videoEl = document.getElementById(`bcvideo_${mainImage.videoId}_html5_api`);
      }

      // if the video element exists
      if (videoEl) {
        // we are on a different asset so pause video
        this.pauseVideo(videoEl);
      }

      const zoomOutClasses = classNames({
        'overlay-mobile__normal-image-wrapper': !(isImage360 || isVideo)
      });

      const zoomImageRef = createRef();
      const quickPinchZoomRef = createRef();
      if (selectedImageIndex === imageIndex) {
        this.selectedQuickPinchZoomRef = quickPinchZoomRef;
        this.selectedZoomedImageRef = zoomImageRef;
      }
      const onUpdate = ({ x, y, scale }) => {
        const { current: img } = zoomImageRef;

        if (img) {
          const value = make3dTransformValue({ x, y, scale });
          img.style.setProperty('transform', value);
        }
      };

      return (
        <div
          key={`trayimage-${imageIndex}`}
          className="overlay-mobile__main-asset"
          style={isManualColor ? { margin: 0 } : {}}
        >
          <div className={zoomOutClasses}>
            {isManualColor && (
              <QuickPinchZoom
                ref={quickPinchZoomRef}
                onUpdate={onUpdate}
                maxZoom={3}
              >
                <div
                  className={classNames('manualSwatch',
                    'sui-aspect-square',
                    'sui-bg-inactive',
                    'sui-flex',
                    'sui-items-center',
                    'sui-justify-center'
                  )}
                >
                  <div className="sui-text-center sui-flex sui-items-center sui-justify-center">
                    <div>
                      <PaintSwatches color="medium" />
                      <div>No Color Preview Available</div>
                    </div>
                  </div>
                </div>
              </QuickPinchZoom>
            )}
            {isCustomColor && (
              <QuickPinchZoom
                ref={quickPinchZoomRef}
                onUpdate={onUpdate}
                maxZoom={3}
              >
                <div
                  style={{
                    backgroundColor: `rgb(${mainImage.rgb.red},${mainImage.rgb.green},${mainImage.rgb.blue})`,
                    display: 'flex',
                    alignItems: 'flex-end',
                    width: '100%',
                    paddingTop: '80%'
                  }}
                >
                  <Image
                    className="overlay-mobile__main-content overlay-mobile__main-image-wrapper"
                    src={mainImage.brandLogo}
                    alt={altLabel}
                    ref={zoomImageRef}
                    loading="lazy"
                    style={{
                      display: 'flex',
                      alignItems: 'flex-end',
                      width: '70%',
                      margin: '0 0 6px 6px',
                      backgroundColor: 'white',
                      padding: '2px'
                    }}
                  />
                </div>
              </QuickPinchZoom>
            )}
            {!isHotspot && isImage && (
              <QuickPinchZoom
                ref={quickPinchZoomRef}
                onUpdate={onUpdate}
                maxZoom={3}
              >
                <img
                  className="overlay-mobile__main-content overlay-mobile__main-image-wrapper"
                  src={zoomedImages[imageIndex].url}
                  alt={altLabel}
                  ref={zoomImageRef}
                  width="auto"
                  height="auto"
                  loading="lazy"
                />
              </QuickPinchZoom>
            )}
            {isImage360
            && (
              <>
                <button
                  type="button"
                  className="overlay-mobile__second-overlay-portrait"
                  onClick={() => this.openSecondaryOverlay(mainImage)}
                >
                  <BackgroundCircleIcon />
                  <ThreeSixtyIcon />
                  <Image
                    className="overlay-mobile__main-content"
                    src={mainImages[imageIndex].mediaStill}
                    alt={altLabel}
                    lazy
                  />
                </button>
              </>
            )}
            {isVideo
            && (
              <>
                <button
                  type="button"
                  className="overlay-mobile__second-overlay-portrait"
                  onClick={() => this.openSecondaryOverlay(mainImage)}
                >
                  <BackgroundCircleIcon />
                  <PlayIcon />
                  <Image
                    className="overlay-mobile__main-content"
                    src={mainImages[imageIndex].videoStill}
                    alt={altLabel}
                    lazy
                  />
                </button>
                {!isPortrait
              && (
                /* eslint-disable-next-line max-len */
                <div className="overlay-mobile__second-overlay-landscape overlay-mobile__main-content overlay-mobile__main-content__video">
                  <VideoPlayer
                    videoId={mainImage.videoId}
                    analyticsData={{
                      section: 'expanded-view',
                      component: 'thumbnails',
                      position: imageIndex.toString()
                    }}
                  />
                </div>
              )}
              </>
            )}
            {isAR && isAvataarAR && (
              <button type="button" onClick={() => this.handleOnPageARClick({ media: mainImage })}>
                <Image
                  className="overlay-mobile__main-content"
                  src={mainImage.mediaStill}
                  alt={altLabel}
                  lazy
                />
                <BackgroundCircleIcon />
                <ThreeSixtyIcon />
              </button>
            )}
            {isAR && !isAvataarAR && (
              <button type="button" onClick={() => this.handleOnPageARClick({ media: mainImage })}>
                <Image
                  className="overlay-mobile__main-content"
                  src={mainImage.mediaStill}
                  alt={altLabel}
                  lazy
                />
                <BackgroundCircleIcon />
                <ARIcon />
              </button>
            )}
            {isHotspot && (
              <InteractiveMediaOverlay
                onClose={this.hideSecondOverlay}
                mainImage={mainImage}
                open={secondOverlayOpened}
                videoAnalyticsData={{
                  section: 'expanded-view',
                  component: 'thumbnails',
                  position: (selectedImageIndex + 1).toString()
                }}
                showAvataarDefault={showAvataarDefault}
                showAvataarAR={showAvataarAR}
                itemId={itemId}
                selectedImageIndex={selectedImageIndex}
              />
            )}
          </div>
        </div>
      );
    });
    this.setState({
      mainImagesBuffer: mainImagesBufferTemp,
      trayMainImages
    });
  };

  pauseVideo = (video) => {
    if (video) {
      video.pause();
    }
  }

  hideSecondOverlay = () => {
    const { handleClose, showAvataarAR } = this.props;
    if (showAvataarAR && handleClose) {
      handleClose();
    }
    this.setState({ secondOverlayOpened: false });
  };

  handleOnPageARClick = ({ media }) => {
    const {
      handleClose, onMediaClick, overlayOpened, itemId, mainImage, showAvataarDefault
    } = this.props;
    if (Helper.containAvataarId(itemId) && showAvataarDefault) {
      this.setState({
        secondOverlayOpened: true,
        mainImage
      });
    } else {
      if (overlayOpened && handleClose) {
        handleClose();
      }
      if (onMediaClick) {
        onMediaClick({
          media
        });
      }
    }

    this.arAssetAnalytics();
  };

  arAssetAnalytics = () => {
    const payload = {
      section: 'see it in your space',
      component: 'augmented reality',
      target: 'expanded-view'
    };
    mediaClick(payload);
  };

  disableScroll = (open) => {
    if (open) {
      document.body.style.overflow = 'hidden';
    }
  };

  determineSwipeTolerance = () => {
    const { mobileOverlayRef } = this;
    const { isPortrait } = this.state;
    const oNode = mobileOverlayRef.current;
    const width = oNode ? oNode.clientWidth : 0;
    const TOLERANCE_FACTOR = 0.55;
    const tolerancex = !isPortrait ? width : width * TOLERANCE_FACTOR;
    return tolerancex || 5;
  };

  render() {
    const {
      handleClose,
      mainImages,
      overlayOpened,
      itemId,
      showAvataarDefault,
      showAvataarAR
    } = this.props;

    const {
      mainImage,
      secondOverlayOpened,
      isPortrait,
      selectedImageIndex,
      trayMainImages,
      trayThumbnails,
    } = this.state;

    const isImage = Helper.isImage({ media: mainImages[selectedImageIndex] });
    const isHotspot = Helper.isHotspot({ media: mainImages[selectedImageIndex] });
    const toleranceSwipe = this.determineSwipeTolerance();
    const collectionLink = mainImages[selectedImageIndex]?.collection;
    const overlayClassName = isHotspot ? 'hotspot-overlay-modal' : '';

    return (
      <Overlay className={overlayClassName} open={overlayOpened} onClose={handleClose}>
        <div className="overlay-mobile" data-component="MediaGalleryOverlayMobile">
          <section className="overlay-mobile__wrapper">
            <div className="overlay-mobile__main-content">
              <button onClick={handleClose} type="button">
                <Image
                  className="overlay-mobile__back-icon"
                  src="https://assets.thdstatic.com/images/v1/back-arrow-grey.svg"
                  alt="Back"
                  lazy
                />
                <span className="overlay-mobile__back-text">Back</span>
              </button>
            </div>
            <Row>
              <Col xs={5}>
                <div className="overlay-mobile__carousel-wrapper" ref={this.mobileOverlayRef}>
                  <Carousel
                    carouselParentRef={this.mobileOverlayRef}
                    showArrows={isPortrait}
                    multiItem={false}
                    showDots={false}
                    selectedItem={selectedImageIndex}
                    onChange={this.onChangeImage}
                    tolerancex={toleranceSwipe}
                  >
                    {trayMainImages}
                  </Carousel>
                  {collectionLink
                  && (
                    <div className="shopThisRoomContainer">
                      <a
                        className="shopThisRoomLink"
                        href={collectionLink}
                        onClick={(e) => e && e.stopPropagation()}
                      >
                        Shop This Room
                      </a>
                    </div>
                  )}

                  {!isHotspot && isImage && <div className="overlay-mobile__image-caption">Pinch Image to Zoom In</div>}
                </div>
              </Col>
              <Col xs={7}>
                <div className="overlay-mobile__thumbnails">
                  <div className="overlay-mobile__thumbnails--portrait">
                    <Carousel
                      showArrows={false}
                      itemWidthMobile="6%"
                      showDots={false}
                      selectedItem={selectedImageIndex}
                    >
                      {trayThumbnails}
                    </Carousel>
                  </div>
                  <div className="overlay-mobile__thumbnails--landscape">
                    {trayThumbnails}
                  </div>
                </div>
              </Col>
            </Row>
          </section>
        </div>
        <Overlay className={overlayClassName} open={secondOverlayOpened} onClose={this.hideSecondOverlay}>
          {secondOverlayOpened
          && (
            <InteractiveMediaOverlay
              onClose={this.hideSecondOverlay}
              mainImage={mainImage}
              open={secondOverlayOpened}
              videoAnalyticsData={{
                section: 'expanded-view',
                component: 'thumbnails',
                position: (selectedImageIndex + 1).toString()
              }}
              showAvataarDefault={showAvataarDefault}
              showAvataarAR={showAvataarAR}
              itemId={itemId}
              selectedImageIndex={selectedImageIndex}
            />
          )}
        </Overlay>
      </Overlay>
    );
  }
}

MediaGalleryOverlayMobile.propTypes = {
  handleClose: func,
  mainImage: shape({}),
  mainImages: arrayOf(shape({})),
  overlayOpened: bool,
  onMediaClick: func,
  selectedImageIndex: number,
  product: shape({}),
  thumbnails: arrayOf(shape({
    id: string,
    location: string,
    mediaType: string,
    altText: string
  })),
  zoomedImages: arrayOf(shape({})),
  overrideData: shape({
    galleryLabel: string,
    brandNameLabel: string
  }),
  itemId: string,
  showAvataarAR: bool,
  showAvataarDefault: bool
};

MediaGalleryOverlayMobile.defaultProps = {
  handleClose: null,
  mainImage: {},
  mainImages: [],
  overlayOpened: false,
  onMediaClick: null,
  selectedImageIndex: 0,
  product: {},
  thumbnails: [],
  zoomedImages: [],
  overrideData: null,
  itemId: null,
  showAvataarAR: false,
  showAvataarDefault: false
};

export default MediaGalleryOverlayMobile;
