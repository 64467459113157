import React from 'react';
import { bool } from 'prop-types';
import classNames from 'classnames';

export const HotspotTagIcon = ({ isThumbnail }) => {
  const classes = classNames('sui-absolute sui-h-12 sui-w-12', {
    'sui-m-auto sui-inset-0 sui-cursor-default sui-pointer-events-none': !isThumbnail,
    'sui-scale-150': isThumbnail
  });
  const styles = {
    background: 'url(https://assets.thdstatic.com/images/v1/product-hotspot.svg) no-repeat center'
  };
  if (!isThumbnail) {
    styles.transform = 'scale(2)';
  }
  return (
    <span
      className={classes}
      data-testid="hotspot-tag-icon"
      style={styles}
    />
  );
};

HotspotTagIcon.propTypes = {
  isThumbnail: bool
};

HotspotTagIcon.defaultProps = {
  isThumbnail: false
};